<template>
  <div class="user-organisation">
    <b-overlay
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
    >
      <img
        class="delete-icon"
        src="@/assets/icons/trash_delete.svg"
        alt="Icon trash delete"
        @click.stop="removeUserRole"
      >
      <form>
        <div
          class="form-row"
          style="align-items: flex-end;"
        >
          <div class="form-group col-5">
            <label style="margin-top: 1em;">
              {{ $t('forms.usergroup.name.label') }}
            </label>
            <p>
              {{ $t('forms.usergroup.name.help') }}
            </p>
            <SearchUsergroups
              :usergroup="usergroup"
              :current-usergroups="currentUsergroups"
              :types="['user-group']"
              :reset-after="false"
              :limit="100"
              :placeholder="$t('forms.usergroup.name.placeholder')"
              @select="selectUsergroup"
            />
          </div>
          <div
            class="form-group col-3"
            :class="{disabled: !formData.group}"
          >
            <label>
              {{ $t('forms.usergroup.role.label') }}
            </label>
            <Multiselect
              v-model="formData.role"
              :options="usergroupsRoles"
              track-by="choice"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('forms.usergroup.role.placeholder')"
              @input="handleBlur"
            />
          </div>
          <div
            class="form-group col-4"
            :class="{disabled: !formData.role}"
          >
            <label>
              {{ $t('forms.usergroup.status.label') }}
            </label>
            <Multiselect
              v-model="formData.roleStatus"
              :options="statusOptions"
              track-by="choice"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('forms.usergroup.status.label')"
              @input="handleBlur"
            />
          </div>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'UserGroupForm',

  components: {
    Multiselect,
    SearchUsergroups
  },

  props: {
    usergroup: {
      type: Object,
      default: () => {
        return {};
      }
    },
    currentUsergroups: {
      type: Array,
      default: () => {
        return [];
      }
    },
    userId: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      default: 'create'
    }
  },

  data() {
    return {
      loading: false,
      formData: {
        group: null,
        role: null,
        roleStatus: null
      },
      statusOptions: [
        {
          choice: 0,
          label: 'Attente de vérification'
        },
        {
          choice: 1,
          label: 'Attente de validation'
        },
        {
          choice: 2,
          label: 'Actif'
        }
      ]
    };
  },

  computed: {
    ...mapState('users', [
      'currentUserRoles'
    ]),
    ...mapState('usergroups', [
      'groups',
      'usergroupsRoles'
    ])
  },

  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        if (newValue && this.usergroup.position) {
          this.$emit('change', {
            position: this.usergroup.position,
            data: {
              group: newValue.group,
              role: newValue.role ? newValue.role.choice : null,
              roleStatus: newValue.roleStatus && newValue.roleStatus.choice !== null ? newValue.roleStatus.choice : null
            },
          });
        }
      }
    }
  },

  created() {
    if (this.groups.length === 0) {
      this.GET_USERGROUPS_GROUPS_LIST({
        direction: null,
        filed: null
      });
    }
    if (this.usergroup.usergroup && this.usergroup.usergroup.id) {
      this.formData.group = this.usergroup.usergroup;
    }
    if (this.usergroup.role !== null) {
      this.formData.role = {
        choice: null,
        label: null
      };
      this.formData.role.choice = this.usergroup.role;
      if (this.usergroupsRoles.length === 0) {
        this.GET_USERGROUPS_ROLES().then(() => {
          this.formData.role.label = this.usergroupsRoles.find(el => el.choice === this.usergroup.role).label;
        });
      } else {
        this.formData.role.label = this.usergroupsRoles.find(el => el.choice === this.usergroup.role).label;
      }
    } else {
      if (this.usergroupsRoles.length === 0) {
        this.GET_USERGROUPS_ROLES();
      }
    }
    if (this.usergroup.roleStatus !== null) {
      this.formData.roleStatus = {
        choice: null,
        label: null
      };
      this.formData.roleStatus.choice = this.usergroup.roleStatus;
      this.formData.roleStatus.label =
        this.statusOptions.find(el => el.choice === this.formData.roleStatus.choice).label;
    }
  },

  methods: {
    ...mapActions('usergroups', [
      'GET_USERGROUPS_GROUPS_LIST',
      'GET_USERGROUPS_ROLES'
    ]),
    ...mapActions('users', [
      'ADD_USER_ROLE',
      'UPDATE_USER_ROLE',
      'REMOVE_USER_ROLE'
    ]),

    selectUsergroup(e) {
      this.formData.group = e;
      this.handleBlur();
    },

    handleBlur() {
      if (this.formData.group !== null && this.formData.role !== null && this.formData.roleStatus !== null) {
        this.$emit('allowAdd', true);
        if (this.mode === 'edit') {
          const userRole = this.currentUserRoles.find(el => el.usergroup.id === this.formData.group.id);
          if (userRole) {
            this.updateUserRole(userRole.id);
          } else {
            this.addUserRole();
          }
        }
      } else {
        this.$emit('allowAdd', false);
      }
    },

    addUserRole() {
      this.loading = true;
      const data = {
        role: this.formData.role.choice,
        status: this.formData.roleStatus.choice,
        user: {
          id: this.userId
        }
      };
      this.ADD_USER_ROLE({
        usergroup: this.formData.group,
        data: data,
        type:'group'
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateUserRole(id) {
      this.loading = true;
      const data = {
        role: this.formData.role.choice,
        status: this.formData.roleStatus.choice,
        user: {
          id: this.userId
        }
      };
      this.UPDATE_USER_ROLE({
        usergroup: this.formData.group,
        userId: id,
        data: data,
        type: 'group'
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    removeUserRole() {
      if (this.formData && this.formData.group) {
        this.loading = true;
        const userRole = this.currentUserRoles.find(el => el.usergroup.id === this.formData.group.id);
        this.REMOVE_USER_ROLE({
          usergroup: this.formData.group,
          userId: userRole.id,
          type: 'organisation'
        })
          .then(() => {
            this.$emit('delete', this.usergroup.position);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$emit('delete');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.user-organisation {
  position: relative;
  box-shadow: 0 0 2px @blue;
  .delete-icon {
    cursor: pointer;
    position: absolute;
    top: 5%;
    left: calc(99% - 2em);
    width: 2em;
  }
  .delete-icon:hover {
    width: 2.2em;
    top: calc(5% - 0.1em);
    left: calc(99% - 2.1em);
  }
}
</style>
