<template>
  <div class="card-container">
    <b-avatar
      :src="avatarUrl"
      size="7rem"
    />
    <div v-if="user.first_name || user.last_name">
      <h5>
        {{ user.first_name }} {{ user.last_name }}
      </h5>
      {{ user.username }}
      <b-badge
        v-if="user.is_superuser"
        pill
        variant="success"
      >
        admin
      </b-badge>
    </div>
    <h4
      v-if="!user.first_name && !user.last_name"
      class="placeholder"
    >
      {{ $t('words.new') }}
    </h4>
    <div
      v-for="usergroup of user.usergroup_roles"
      :key="usergroup.id"
      style="text-align: center; font-size: 0.9em;"
    >
      {{ usergroup.usergroup.display_name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCard',

  props: {
    user: {
      type: Object,
      default: () => { return {}; }
    }
  },

  computed: {
    avatarUrl() {
      if (this.user && this.user.id) {
        return `https://robohash.org/${this.user.id}`;
      }
      return '';
    }
  }
};
</script>

<style scoped>
.card-container {
  padding-top: 2rem;
}
/* h4 {
  margin-bottom: 1rem !important;
} */
.placeholder {
  color: rgb(196, 196, 196);
}</style>
